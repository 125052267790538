import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  version: [],
  search: "",
  totalCount: 0,
  rowsPerPage: 5,
  currentPage: 1,
  lastPage: 0,
};

export const versionHistorySlice = createSlice({
  name: "version",
  initialState,
  reducers: {
    addVersionHistory(state, action) {
      state.version.push(action.payload);
    },
    getVersionHistory(state, action) {
      state.version = action.payload.version;
      state.totalCount = action.payload.totalCount;
      state.currentPage = action.payload.currentPage;
      state.lastPage = action.payload.lastPage;
      state.rowsPerPage = action.payload.rowsPerPage;
      state.search = action.payload.search;
    },
  },
});

export const actions = versionHistorySlice.actions;
export default versionHistorySlice.reducer;
