// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

// ** UseJWT import to get config
import useJwt from "../../../../src/auth/jwt/useJwt";

const config = useJwt.jwtConfig;

const initialUser = () => {
  const item = localStorage.getItem("userData");
  return item ? JSON.parse(item) : null;
};

const initialToken = () => {
  const item = localStorage.getItem(config.storageTokenKeyName);
  return item ? item : null;
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
    accessToken: initialToken(),
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload.user;
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName];
      // state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem("userData", JSON.stringify(action.payload.user));
      localStorage.setItem(
        config.storageTokenKeyName,
        action.payload.accessToken
      );
      // localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: (state) => {
      state.userData = null;
      state[config.storageTokenKeyName] = null;
      state[config.storageRefreshTokenKeyName] = null;
      localStorage.removeItem("userData");
      localStorage.removeItem(config.storageTokenKeyName);
      // localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
    updateUserDetail: (state, action) => {
      // console.log(
      //   "🚀 ~ file: AuthSlice.js:50 ~ action.payload.:",
      //   state.userData
      // );

      state.userData.firstName = action.payload.firstName;
      state.userData.lastName = action.payload.lastName;
      state.userData.attachment = action.payload.attachment;
      // Following line is added to stop vanished data after save when user refresh page.
      localStorage.setItem("userData", JSON.stringify(state.userData));
    },
  },
});

export const { handleLogin, handleLogout, updateUserDetail } =
  authSlice.actions;

export default authSlice.reducer;
