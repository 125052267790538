import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projects: [],
  search: "",
  totalCount: 0,
  rowsPerPage: 5,
  currentPage: 1,
  lastPage: 0,
  project: {},
};

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    addProject(state, action) {
      state.projects.push(action.payload);
    },
    getProjects(state, action) {
      state.projects = action.payload.projects;
      state.totalCount = action.payload.totalCount;
      state.currentPage = action.payload.currentPage;
      state.lastPage = action.payload.lastPage;
      state.rowsPerPage = action.payload.rowsPerPage;
      state.search = action.payload.search;
    },
  },
});

export const actions = projectSlice.actions;
export default projectSlice.reducer;
