import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listing: [],
  roleTypes: [],
  search: "",
  totalCount: 0,
  rowsPerPage: 5,
  currentPage: 1,
  lastPage: 0,
};

export const roleTypeSlice = createSlice({
  name: "roleTypes",
  initialState,
  reducers: {
    addRoleTypes(state, action) {
      state.roleTypes.push(action.payload);
    },
    getRoleTypes(state, action) {
      state.roleTypes = action.payload.roleTypes;
      state.totalCount = action.payload.totalCount;
      state.currentPage = action.payload.currentPage;
      state.lastPage = action.payload.lastPage;
      state.rowsPerPage = action.payload.rowsPerPage;
      state.search = action.payload.search;
    },
    getAllRoleTypes(state, action) {
      state.listing = action.payload;
      console.log(action.payload);
    },
    getRoleTypeById(state, action) {
      state.roleTypes = action.payload;
    },
  },
});

export const actions = roleTypeSlice.actions;
export default roleTypeSlice.reducer;
