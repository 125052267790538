const API_URL = process.env.REACT_APP_API_URL
const AUTH_URL = `${API_URL}/auth`

// ** Auth Endpoints
export default {
  loginEndpoint: `${AUTH_URL}/login`,
  registerEndpoint: `${AUTH_URL}/signup`,
  refreshEndpoint: '/jwt/refresh-token',
  forgotPasswordEndpoint: `${AUTH_URL}/forgot/password`,
  resetPasswordEndpoint: `${AUTH_URL}/reset/password`,
  verifyTokenEndpoint: `${AUTH_URL}/verify/token`,
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
