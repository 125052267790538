import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  testRuns: [],
  testRun: "",
  search: "",
  totalCount: 0,
  rowsPerPage: 5,
  currentPage: 1,
  currentRunPage: 1,
  lastPage: 0,
  testRunId:"",
  stats: {},
};


export const testRunSlice = createSlice({
    name: "testRuns",
    initialState,
    reducers: {
      addTestRun(state, action) {
        state.testRuns.push(action.payload);
      },
      getTestRunById(state, action) {
        state.testRuns = action.payload;
      },
      getTestRun(state, action) {
        state.testRuns = action.payload.testRuns;
        state.totalCount = action.payload.totalCount;
        state.currentPage = action.payload.currentPage;
        state.lastPage = action.payload.lastPage;
        state.rowsPerPage = action.payload.rowsPerPage;
        state.search = action.payload.search;
      },
      getTestRunCase(state, action) {
        state.testRun = action.payload.testRuns;
        state.totalCount = action.payload.totalCount;
        state.currentRunPage = action.payload.currentRunPage;
        state.lastPage = action.payload.lastPage;
        state.rowsPerPage = action.payload.rowsPerPage;
        state.search = action.payload.search;
        state.testRunId = action.payload.testrun;
      },
      setTestRunCaseStats(state, action) {
        state.stats = action.payload;
      },
    },
  });
  
  export const actions = testRunSlice.actions;
  export default testRunSlice.reducer;
  