import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  testSuites: [],
  search: "",
  totalCount: 0,
  rowsPerPage: 5,
  currentPage: 1,
  lastPage: 0,
};

export const testSuiteSlice = createSlice({
  name: "testSuites",
  initialState,
  reducers: {
    addTestSuite(state, action) {
      state.testSuites.push(action.payload);
    },
    getTestSuite(state, action) {
      state.testSuites = action.payload.testSuites;
      state.totalCount = action.payload.totalCount;
      state.currentPage = action.payload.currentPage;
      state.lastPage = action.payload.lastPage;
      state.rowsPerPage = action.payload.rowsPerPage;
      state.search = action.payload.search;
    },
  },
});

export const actions = testSuiteSlice.actions;
export default testSuiteSlice.reducer;
