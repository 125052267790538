// ** Reducers Imports
import layout from './layout'
import auth from '../modules/authentication/store/AuthSlice'
import projects  from '../modules/projects/store/ProjectSlice'
import testSuites  from '../modules/testSuites/store/TestSuiteSlice'
import testCases  from '../modules/testCases/store/TestCaseSlice'
import users  from '../modules/users/store/UserSlice'
import testRuns  from '../modules/testRuns/store/TestRunSlice'
import suiteType  from '../../src/pages/account-settings/SuiteType/store/SuiteTypeSlice'
import version from '../modules/versionHistory/store/VersionSlice';
import versionCase from '../modules/suiteExecution/store/SuiteExecutionSlice';
import Roles from '../modules/roles/store/RoleSlice'
import testEnvironment from '../../src/pages/account-settings/TestEnvironment/store/TestEnvironmentSlice'
import roleType from '../pages/account-settings/RoleTypes/store/RoleTypeSlice'


const rootReducer = {
  auth,
  layout,
  projects,
  testSuites,
  testCases,
  suiteType,
  testRuns,
  users,
  version,
  versionCase,
  Roles,
  testEnvironment,
  roleType

}

export default rootReducer
