// import { Ability } from '@casl/ability'
// import { initialAbility } from './initialAbility'

// //  Read ability from localStorage
// // * Handles auto fetching previous abilities if already logged in user
// // ? You can update this if you store user abilities to more secure place
// // ! Anyone can update localStorage so be careful and please update this
// const userData = JSON.parse(localStorage.getItem('userData'))
// const existingAbility = userData ? userData.ability : null

// export default new Ability(existingAbility || initialAbility)
import { AbilityBuilder } from "@casl/ability";
import { defineAbility } from "@casl/ability";

export function updateAbility(ability, user) {
  const { can, rules } = new AbilityBuilder(user);
  const userRoles = user?.role;
  Object.entries(userRoles.permissions)?.forEach(([subject, value]) => {
    Object?.entries(value)?.forEach(([i, action]) => {
      let cond = "";
      can(action, subject, cond);
    });
  });
  ability.update(rules);
}

export default defineAbility((can, cannot) => {
  can("read", "Dashboard");
});
