import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  testCases: [],
  search: "",
  totalCount: 0,
  rowsPerPage: 5,
  currentPage: 1,
  lastPage: 0,
  testCase: {},
};

export const testCaseSlice = createSlice({
  name: "testCases",
  initialState,
  reducers: {
    addTestCase(state, action) {
      state.testCases.push(action.payload);
    },
    getTestCase(state, action) {
      state.testCases = action.payload.testCases;
      state.totalCount = action.payload.totalCount;
      state.currentPage = action.payload.currentPage;
      state.lastPage = action.payload.lastPage;
      state.rowsPerPage = action.payload.rowsPerPage;
      state.search = action.payload.search;
      state.testsuite = action.payload.testsuite;
    },
    getTestCaseById(state, action) {
      state.testCase = action.payload;
    },
    
  }
});

export const actions = testCaseSlice.actions;
export default testCaseSlice.reducer;
