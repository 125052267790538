import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  environments: [],
  search: "",
  totalCount: 0,
  rowsPerPage: 5,
  currentPage: 1,
  lastPage: 0,
};

export const suiteTypeSlice = createSlice({
    name: "environments",
    initialState,
    reducers: {
      addTestEnvironment(state, action) {
        state.environments.push(action.payload);
      },
      getTestEnvironments(state, action) {
        state.environments = action.payload.testEnvironments;
        state.totalCount = action.payload.totalCount;
        state.currentPage = action.payload.currentPage;
        state.lastPage = action.payload.lastPage;
        state.rowsPerPage = action.payload.rowsPerPage;
        state.search = action.payload.search;
      },
      getTestEnvironmentById(state, action) {
        state.environments = action.payload;
      },
    },
  });
  
  export const actions = suiteTypeSlice.actions;
  export default suiteTypeSlice.reducer;