import {
    createSlice
} from "@reduxjs/toolkit";

const initialState = {
    users: [],
    search: "",
    totalCount: 0,
    rowsPerPage: 5,
    currentPage: 1,
    lastPage: 0,
    user: {},
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addUser(state, action) {
            state.users.push(action.payload);
        },
        getUser(state, action) {
            state.users = action.payload.users;
            state.totalCount = action.payload.totalCount;
            state.currentPage = action.payload.currentPage;
            state.lastPage = action.payload.lastPage;
            state.rowsPerPage = action.payload.rowsPerPage;
            state.search = action.payload.search;
        },
        getUserById(state, action) {
            state.user = action.payload;
        },
        deleteUser(state, action) {
            const index = state.user.findIndex(x => x._id === action.payload._id);
            state.user.splice(index, 1)
        }
    },
});

export const actions = userSlice.actions;
export default userSlice.reducer;