// ** React Imports
import { Fragment } from "react";

// ** Reactstrap Imports
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";

const ProjectDelete = ({ isOpen, handleClose, id }) => {

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {handleClose(false)}}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={() => {handleClose(false)}}>Delete Test Case</ModalHeader>
        <ModalBody>
          <h4>Are you sure you want to delete this test case ?</h4>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => {handleClose(false)}}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => {handleClose(true)}}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ProjectDelete;
