import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  versionCase: [],
  testCaseStatus: [],
  search: "",
  totalCount: 0,
  rowsPerPage: 5,
  currentPage: 1,
  lastPage: 0,
};

export const versionCaseSlice = createSlice({
    name: "versionCase",
    initialState,
    reducers: {
      addVersionCase(state, action) {
        state.versionCase.push(action.payload);
      },
      getVersionCase(state, action) {
        state.versionCase = action.payload.versionCase;
        state.totalCount = action.payload.totalCount;
        state.currentPage = action.payload.currentPage;
        state.lastPage = action.payload.lastPage;
        state.rowsPerPage = action.payload.rowsPerPage;
        state.search = action.payload.search;
      },
      getVersionCaseById(state, action) {
        state.versionCase = action.payload;
      },
      getTestCaseStatusFromVersion(state, action) {
        state.testCaseStatus = action.payload.testCases;
        state.totalCount = action.payload.totalCount;
        state.currentPage = action.payload.currentPage;
        state.lastPage = action.payload.lastPage;
        state.rowsPerPage = action.payload.rowsPerPage;
        state.search = action.payload.search;
      }
      
    },
  });
  
  export const actions = versionCaseSlice.actions;
  export default versionCaseSlice.reducer;