import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roles: [],
  search: "",
  totalCount: 0,
  rowsPerPage: 5,
  currentPage: 1,
  lastPage: 0,
  // project: {},
};

export const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    addRole(state, action) {
      state.roles.push(action.payload);
    },
    getRoles(state, action) {
      state.roles = action.payload.roles;
      state.totalCount = action.payload.totalCount;
      state.currentPage = action.payload.currentPage;
      state.lastPage = action.payload.lastPage;
      state.rowsPerPage = action.payload.rowsPerPage;
      state.search = action.payload.search;
    },
    getRolesWithUsers(state, action){
      state.roles = action.payload;
    }
  },
});

export const actions = roleSlice.actions;
export default roleSlice.reducer;
